<template>
  <div class="h-full">
    <dropdown
      menuPadding="px-4"
      color="gray-200"
      text-color="oCharcoal"
      :no-hover="true"
    >
      <template v-slot:button>
        <div class="px-2">
          <span class="font-bold" style="white-space: nowrap;">
            <slot name="title"></slot>
          </span>
          <span class="rounded-full items-center justify-center ml-2">
            <i class="fas fa-caret-down"></i>
          </span>
        </div>
      </template>
      <template v-slot:menu>
        <slot name="option"></slot>
        <!-- <div class="p-3">
          <strong>{{ appliedFilter[i]["options"]["name"] }}</strong>
          <multi-select-list
            v-if="appliedFilter[i]['type'] === 'multiSelect'"
            @change="fleetFilterChange"
            :options="appliedFilter[i]['options']['options']"
            item-label="name"
          ></multi-select-list>
          <range-slider
            v-else-if="appliedFilter[i]['type'] === 'range'"
            @change="lastRideChange"
            :range="appliedFilter[i]['options']['key']"
            unit=" Days"
            :max="21"
          />
          <t-toggle
            v-else-if="appliedFilter[i]['type'] === 'switch'"
            value="true"
            v-model="appliedFilter[i]['options']['key']"
            unchecked-value="false"
            checked-value="true"
            checked-label="yes"
            unchecked-label="No"
          />
        </div> -->
        <div class="text-right mt-1 border-t p-3" style="min-width:170px">
          <button class="px-2 py-1  rounded" @click="removeFilter">
            Clear
          </button>
          <button
            class="px-3 py-1 bg-green-500 mr-1 text-white rounded"
            @click="applyFilter"
          >
            Apply
          </button>
        </div>
      </template>
    </dropdown>
    <!-- <dropdown
      :v-show="true"
      button-class="bg-gray-100 border  py-1 px-4 mx-1 rounded-full"
      :no-hover="true"
    >
      <template v-slot:button>
        <div class="font-bold" style="min-width:29px">{{ 6 }}+</div>
      </template>
      <template v-slot:menu>
        <template v-for="filter in 6">
          <dropdown
            :v-show="true"
            menuPadding="p-0"
            color="blue-700"
            text-color="white"
            :no-hover="true"
            :key="'dropdown' + filter"
          >
            <template v-slot:button>
              <span class="font-bold" style="white-space: nowrap;">
                {{ "something" }}
              </span>
              <span class="rounded-full items-center justify-center ml-1">
                <i class="fas fa-caret-down"></i>
              </span>
            </template>
            <template v-slot:menu>
              <div class="p-3">
                <strong>Data</strong>
              </div>
              <div class="text-right mt-1 border-t p-3" style="min-width:170px">
                <button
                  @click.stop="$emit('clear-selected-filter', filter)"
                  class="px-2 py-1  rounded"
                >
                  Clear
                </button>
                <button
                  @click="$emit('apply-filter')"
                  class="px-3 py-1 bg-green-500 mr-1 text-white rounded"
                >
                  Apply
                </button>
              </div>
            </template>
          </dropdown>
        </template>
      </template>
    </dropdown> -->
  </div>
</template>
<script>
import Dropdown from './Dropdown'
export default {
  props: {},
  components: {
    Dropdown,
  },
  computed: {},
  mounted() {},
  methods: {
    applyFilter() {
      this.$emit('apply-filter')
    },
    removeFilter() {
      this.$emit('remove-filter')
    },
  },
}
</script>
