<template>
  <div ref="dropdownContainer" class="dropdown h-full inline-block relative">
    <button
      ref="dropdownButton"
      @click="open = !open"
      :class="[buttonClass, buttonClassExt, bgColor]"
      style="outline:none;"
    >
      <slot name="button"></slot>
      <span v-if="hasArrow">
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </span>
      <!-- <i  class="fas fa-chevron-down"></i> -->
    </button>
    <div
      v-show="open"
      :class="menuContainerClass"
      class="dropdown-menu mt-2  absolute min-w-full pb-3"
      style="z-index:2;"
    >
      <div :class="menuClass" class="border-0 bg-white shadow-md rounded-lg">
        <slot name="menu"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: String,
      default: 'rounded-full',
    },
    color: {
      type: String,
      default: 'gray-300',
    },
    textColor: {
      type: String,
      default: 'white',
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    buttonClassExt: {
      type: String,
      default:
        'outline-none border text-14px inline-flex items-center whitespace-nowrap py-1 px-2 mx-1',
    },
    menuPadding: {
      type: [String, Number],
      default: 'p-3',
    },
  },
  mounted() {
    document.addEventListener('click', this.autoCloseEventListener)
  },
  data() {
    return {
      open: false,
      hovered: false,
    }
  },
  methods: {
    autoCloseEventListener(e) {
      // trigger if mouse is clicked outside of notifcation
      const dropdownContainer = this.$refs.dropdownContainer
      if (
        typeof dropdownContainer !== 'undefined' &&
        dropdownContainer !== e.target &&
        !dropdownContainer.contains(e.target)
      ) {
        this.open = false
        this.hovered = false
      }
    },
    _close() {
      this.open = false
      this.hovered = false
    },
  },
  watch: {
    open(open) {
      if (open) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
  },
  computed: {
    bgColor() {
      return this.open
        ? 'bg-white text-oCharcoal'
        : 'bg-' + this.color + ' text-' + 'oCharcoal'
    },
    shadow() {
      return this.open ? 'shadow-md' : ''
    },
    buttonClass() {
      return this.borderRadius + ' ' + this.bgColor + ' ' + this.shadow
    },
    menuContainerClass() {
      return this.size
    },
    menuClass() {
      let padding = ''
      if (typeof this.menuPadding === 'string') {
        padding = this.menuPadding
      }
      return padding
    },
  },
}
</script>
<style scoped>
.dropdown > button {
  height: 100%;
}
.dropdown-menu.lg {
  min-width: 181px;
  width: auto;
  max-width: calc(100vw - 50px);
  /* width: calc(100vw - 70px) */
}
@media screen and (min-width: 640px) {
  .dropdown-menu.lg {
    min-width: 330px;
    width: 50vw;
  }
}
</style>
